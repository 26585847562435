import * as React from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'
import Article from '../components/article'

const Privacy = () => {
return (
<>
    <Seo pageTitle={'Privacy Policy'} description={`Privacy Policy`} />
    <Layout>
        <main>
            <Article>
                <h1 className='display-4'>Privacy Policy</h1>
                <div>
                    <p>
                        We are Legacy Home Protect Limited, a company registered in England & Wales under company number
                        8722216 whose
                        registered address is Provincial House, 37 New Walk, Leicester, LE1 6TE.
                    </p>
                    <p>
                        The EU General Data Protection Regulation, which applies from 25th May 2018 gives individuals
                        more control over
                        how their personal data is used. The Regulation sets out your rights as an individual regarding
                        how your
                        personal data is collected, used and stored, including your rights to have data corrected or
                        removed. This
                        notice sets out the purpose for which personal data collected by us, or provided by you, will be
                        processed.
                    </p>
                    <p>
                        We reserve the right to alter this Privacy Notice from time to time by updating this page in
                        order to reflect
                        any changes in the law or changes to our privacy practices. You may therefore wish to check this
                        privacy notice
                        for changes whenever you visit our website.
                    </p>
                    <p>
                        We respect the privacy of every individual who visits our website and strongly believe in
                        protecting the privacy
                        of our clients’ personal information.
                    </p>
                    <p>
                        By disclosing your personal data to us using this website or contacting us by email, fax, post
                        or telephone you
                        consent to the collection, storage and processing of your personal data by us in a manner set
                        out in this
                        Privacy Policy.
                    </p>
                    <p>
                        We will not collect any of your personal data (i.e. your name, address, or other personal
                        information) when you
                        visit our website, unless you give it to us.
                    </p>
                    <p>
                        Please note that when you view the site we may obtain and hold certain anonymous data
                        (information which does
                        not identify you) such as the type of web browser you are using or details of the site from
                        which you are linked
                        to our site.
                    </p>
                    <p>
                        For information about cookies please view the separate link on our website.
                    </p>
                    <p>
                        If for any reason you contact us by email, fax, post or telephone we will use your contact
                        details in order to
                        deal with your enquiry.
                    </p>
                    <p>
                        We will use information we hold about you to provide services to you under our contract with you
                        including – to
                        establish your home insurance requirements, establish appropriate levels of cover, to discuss
                        additional
                        insurance products that may be appropriate to your circumstances and to send you regular
                        information about any
                        changes in legislation that may affect you.
                    </p>
                    <p>
                        We may occasionally use client data within a closed, secure environment for development purposes
                        to enable us to
                        test our systems, monitor system security, and improve the service to our customers. Any data
                        processed during
                        testing is deleted once the project is complete.
                    </p>
                    <p>
                        We will never pass on, sell, trade or rent your personal data to any other parties without your
                        consent. We will
                        only share your data with business partners that we are required to disclose the information to
                        in connection
                        with the performance of our contract with you, for example our principle Cavere insurance and
                        associated
                        insurers.
                    </p>
                    <p>
                        Depending on your circumstances, and the products selected, it will be necessary to share your
                        details with our
                        principle Cavere insurance limited in order to produce your insurance documents. Again, we will
                        advise you if
                        this is necessary in order to obtain your prior consent.
                    </p>
                    <p>
                        We may also need to share your data with third parties in order to comply with any legal or
                        regulatory
                        obligation we have, which may include exchanging information with other companies and
                        organisations for the
                        purpose of the detection of financial and other crime, or fraud prevention.
                    </p>
                    <p>
                        <b>Please note that if you withhold your consent for information to be passed to third parties
                            when requested
                            meaning they do not have the data they need, we may be unable to supply you with the
                            services you have asked
                            us to provide.</b>
                    </p>
                    <p>
                        We will hold your personal information based on the following criteria:
                    <ul>
                        <li>For as long as we have reasonable business needs, such as managing our relationship with you
                            and managing
                            our operations.</li>
                        <li>For as long as we provide services to you and then for as long as someone could bring a
                            claim against us.
                        </li>
                        <li>Retention periods in line with any legal and regulatory requirements or guidance.</li>
                    </ul>
                    </p>
                    <h3>YOUR RIGHTS IN RESPECT OF YOUR PERSONAL INFORMATION</h3>
                    <h4>Right of access</h4>
                    <p>You have the right to request a copy of the personal information we hold about you at any time.
                    </p>

                    <h4>Right to rectification</h4>

                    <p>If at any time you believe that the data we hold for you is inaccurate or incomplete you may ask
                        us to correct or
                        remove it. We will be happy to review, update or remove information as appropriate, but please
                        be aware we may
                        need to retain your information on our files to resolve disputes, or for technical and legal
                        requirements. If we
                        have disclosed your personal data to a third party we will also contact them to inform them of
                        the
                        rectification.</p>

                    <h4>Withdrawing your consent</h4>

                    <p>Where we are processing your personal data because you have consented to us doing do, you have a
                        right to
                        withdraw your consent to us processing your data at any time. Please note however that the
                        withdrawal of your
                        consent will not affect any use of your personal information made before you withdrew your
                        consent.</p>

                    <h4>Objecting to processing</h4>

                    <p>If we process your personal data because we have a legitimate interest in doing so, you may
                        object to that
                        processing.</p>

                    <h4>Right of erasure</h4>

                    <p>This is also known as “right to be forgotten”. You have the right to request us to erase all
                        personal data about
                        you, such as where the data is no longer necessary for the purposes for which it was originally
                        collected and
                        there is no compelling reason for retaining it. We reserve the right to retain your personal
                        data if we have a
                        valid reason to do so, such as to comply with a legal or regulatory obligation.</p>

                    <h4>Complaints</h4>

                    <p>If you are not satisfied how we have handled your personal data – if it is wrong, it has been
                        lost or we have
                        disclosed it to someone else without your agreement, then you can either:- write to the
                        Designated Data
                        Controller at Legacy Home Protect Limited, 37 New Walk, Leicester, LE1 6TE, email:
                        dataprotection@legacyops.com
                        , or telephone 0345 260 0600. You may also complain to the Information Commissioner’s Office at
                        www.ico.org.uk
                        or by telephoning 0303 123 1113.
                    </p>
                </div>
            </Article>
        </main>
    </Layout>
</>
)
}

export default Privacy
